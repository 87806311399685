import Error from '@components/misc/error'
import {GetStaticProps, NextPage} from 'next'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'

const ErrorPage: NextPage = () => {
  return <Error statusCode={404} />
}

export const getStaticProps: GetStaticProps = async () => {
  const props = await serverSideTranslations('fr', ['common'])
  return {props}
}

export default ErrorPage
